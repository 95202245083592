/*
 * scrollToElem
 */

export const scrollToElem = (elem, offset = 0) => {
    let headerHeight = document.querySelector('.header').offsetHeight;

    if(typeof(document.querySelector('.js-sticky')) != 'undefined' && document.querySelector('.js-sticky') != null) {
        headerHeight += document.querySelector('.js-sticky').offsetHeight;
    }

    const y = elem.getBoundingClientRect().top + window.pageYOffset - headerHeight - offset;

    setTimeout(function () {
        window.scrollTo({
            top : y,
            behavior : 'smooth'
        });
    }, 1);
};


/*
 * elemSiblings
 */

export const elemSiblings = n => [...n.parentElement.children].filter(c=>c!=n);


/*
 * lockScroll
 */

export function lockScroll() {
    document.querySelector('html').classList.add('has-cc-lock');
}


/*
 * unlockScroll
 */

export function unlockScroll() {
    document.querySelector('html').classList.remove('has-cc-lock');
}


/*
 * luminance
 */

export function luminance(r, g, b) {
    let a = [r, g, b].map((v) => {
        v /= 255;

        return v <= 0.03928
            ? v / 12.92
            : Math.pow( (v + 0.055) / 1.055, 2.4 );
    });

    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}


/*
 * contrast
 */

export function contrast(rgb1, rgb2) {
    let lum1 = luminance(rgb1[0], rgb1[1], rgb1[2]);
    let lum2 = luminance(rgb2[0], rgb2[1], rgb2[2]);
    let brightest = Math.max(lum1, lum2);
    let darkest = Math.min(lum1, lum2);

    return (brightest + 0.05)
         / (darkest + 0.05);
}


/*
 * serializeForm
 */

export function serializeForm(form) {
    // Setup our serialized data
    var serialized = []; // Loop through each field in the form

    for (var i = 0; i < form.elements.length; i++) {
        var field = form.elements[i]; // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields

        if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue; // If a multi-select, get all selections

        if (field.type === 'select-multiple') {
        for (var n = 0; n < field.options.length; n++) {
            if (!field.options[n].selected) continue;
            serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
        }
        } // Convert field data to a query string
        else if (field.type !== 'checkbox' && field.type !== 'radio' || field.checked) {
            serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
        }
    }

    return serialized.join('&');
}
