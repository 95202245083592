'use strict';

export default class FlashMessage {
    constructor() {
        window.addEventListener('click', this.closeFlashMessage, false);
    }

    closeFlashMessage(event) {
        if (event.target.classList.contains('js-flash-message_close')) {
            event.preventDefault();
            event.stopPropagation();
            event.target.blur();

            dismissMessage(event.target.closest('.js-flash-message:not(.js-alert-template)'));

            window.removeEventListener('click', this.closeFlashMessage, false);
        }
    }
}

export function createAlertMsg(type, msg) {
    let alertList = document.querySelector('#alert-list');
    let alert = document.querySelector('.js-alert-template').cloneNode(true);

    alert.classList.remove('hide-desktop', 'hide-mobile', 'js-alert-template');
    alert.classList.add('flash_' + type);
    alert.querySelector('.js-alert-message').innerHTML = msg;

    alertList.appendChild(alert);
    alertList.classList.remove('is-all-dismissed');
    alertList.classList.add('is-active');

    setTimeout(() => {
        dismissMessage(alert);
    }, 7200);
}

export function dismissMessage(msg) {
    msg.classList.add('is-dismissed');

    setTimeout(() => {
        msg.remove();
    }, 600);
}
