'use strict';

export default class Accordion {
    constructor() {
        this.toggle = document.querySelectorAll('.js-accordion_trigger');

        this.listeners();
    }

    listeners() {
        this.toggle.forEach(el => {
            if (!document.getElementById(el.dataset.block).classList.contains('open'))
                document.getElementById(el.dataset.block).querySelector('.js-accordion_container').style.display = 'none';

            el.addEventListener('click', this.toggleAccordion, false);
        });

        // Invalid Accordion Forms
        if(typeof(document.querySelector('.js-accordion_block')) != 'undefined' && document.querySelector('.js-accordion_block') != null) {
            document.querySelectorAll('.js-accordion_block').forEach(accordion => {
                let formsInvalid = accordion.querySelectorAll('.is-invalid');
                if (formsInvalid.length > 0) {
                    accordion.classList.add('open');
                    accordion.querySelector('.js-accordion_container').style.display = 'block';
                }
            });
        }
    }

    toggleAccordion(el) {
        let accBlock = document.getElementById(el.currentTarget.dataset.block);
        let accContainer = accBlock.querySelector('.js-accordion_container');

        if (!accContainer.classList.contains('is-animating')) {
            if (accBlock.classList.contains('open')) {
                accBlock.classList.remove('open');
                slideUp(accContainer);
            } else {
                accBlock.classList.add('open');
                slideDown(accContainer);
            }

            // Check for open modals
            if (accBlock.closest('.js-modal_wrapper.open')) {
                setTimeout(() => {
                    let modal = accBlock.closest('.js-modal_wrapper.open');
                    let heightBody = window.innerHeight;
                    let heightModalContent = modal.querySelector('.js-modal_content').offsetHeight;

                    if (heightModalContent >= heightBody)
                        modal.classList.add('fixedHeight');
                    else
                        modal.classList.remove('fixedHeight');
                }, 300)
            }
        }
    }
}

let slideUp = (target, duration = 300) => {
    target.classList.add('is-animating');

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;

    window.setTimeout( () => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');

        target.classList.remove('is-animating');
    }, duration);
}

let slideDown = (target, duration = 300) => {
    target.classList.add('is-animating');

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;

    if (display === 'none')
        display = 'block';

    target.style.display = display;

    let height = target.offsetHeight;

    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');

    window.setTimeout( () => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');

        target.classList.remove('is-animating');
    }, duration);
}

let slideToggle = (target, duration = 300) => {
    if (window.getComputedStyle(target).display === 'none') {
        return slideDown(target, duration);
    } else {
        return slideUp(target, duration);
    }
}
