"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

(function (window, document, undefined) {
  var recaptchaLoaded = false;
  var submitter = null;
  var defaults = {
    // Selectors
    alertSelector: '.js-alert',
    alertList: '#alert-list',
    alertTemplate: '.js-alert-template',
    fieldSelector: '.js-field',
    fieldControlSelector: '.js-field-control',
    fieldFeedbackSelector: '.js-field-feedback',
    // AJAX
    ajax: false,
    ajaxSuccessCallback: null,
    // ajaxSuccessCallback(form) {},
    ajaxErrorCallback: null,
    // ajaxErrorCallback(form) {},
    // Animation Functions
    animationShowFeedback: null,
    // animationShowFeedback(feedback) {},
    animationHideFeedback: null,
    // animationHideFeedback(feedback) {},
    animationShowAlert: null,
    // animationShowAlert(alert) {},
    animationHideAlert: null,
    // animationHideAlert(alert, done) {},
    // reCAPTCHA Settings
    recaptchaSiteKey: undefined,
    // Pass in object of custom validations
    customValidations: {},
    // Localisation for translation support
    localisation: {
      validity: {
        valueMissing: 'This is a required field',
        typeMismatchEmail: 'Please enter a valid email address',
        typeMismatchUrl: 'Please enter a valid website address',
        tooShort: 'Must be a minimum of $0 characters',
        tooLong: 'Must be a maximum of $0 characters',
        rangeUnderflow: 'Must be above $0',
        rangeOverflow: 'Must not exceed $0',
        patternMismatch: 'Please match the format requested',
        default: 'There was a problem with the inputted data',
        multicheckMissing: 'Please select an option',
        multicheckMin: 'Please select a minimum of $0 options',
        multicheckMax: 'Please select a maximum of $0 options'
      },
      alerts: {
        recaptchaExpired: 'reCAPTCHA has expired, please try again',
        recaptchaProblem: 'There is a problem with reCAPTCHA, please try again',
        submissionProblem: 'There was a problem submitting the form, please try again',
        submissionSuccess: 'The form was submitted successfully'
      }
    }
  };
  /**
   * Helper functions
   * @private
   */

  function deepExtend(out) {
    out = out || {};

    for (var i = 0; i < (arguments.length <= 1 ? 0 : arguments.length - 1); i++) {
      var obj = i + 1 < 1 || arguments.length <= i + 1 ? undefined : arguments[i + 1];

      if (!obj) {
        continue;
      }

      for (var key in obj) {
        if (obj.hasOwnProperty(key)) {
          if (_typeof(obj[key]) === 'object') {
            out[key] = deepExtend(out[key], obj[key]);
          } else {
            out[key] = obj[key];
          }
        }
      }
    }

    return out;
  }
  /**
   * Asynchronously load scripts in with a callback
   * @param {String} source URL of script file
   * @param {Function} loadCallback On load callback
   * @param {Function} errorCallback On error callback
   */


  function getScript(source, loadCallback, errorCallback) {
    var script = document.createElement('script');
    var prior = document.getElementsByTagName('script')[0];
    script.async = 1;

    script.onload = script.onreadystatechange = function (_, isAbort) {
      if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
        script.onload = script.onreadystatechange = null;
        script = undefined;

        if (!isAbort && loadCallback) {
          loadCallback();
        }
      }
    };

    if (errorCallback) {
      script.onerror = function () {
        errorCallback();
      };
    }

    script.src = source;
    prior.parentNode.insertBefore(script, prior);
  }
  /**
   * Serialize all form data into a query string
   * @param  {Node} form The form to serialize
   * @return {String} The serialized form data
   */


  function serialize(form) {
    // Setup our serialized data
    var serialized = []; // Loop through each field in the form

    for (var i = 0; i < form.elements.length; i++) {
      var field = form.elements[i]; // Don't serialize fields without a name, submits, buttons, file and reset inputs, and disabled fields

      if (!field.name || field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') continue; // If a multi-select, get all selections

      if (field.type === 'select-multiple') {
        for (var n = 0; n < field.options.length; n++) {
          if (!field.options[n].selected) continue;
          serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.options[n].value));
        }
      } // Convert field data to a query string
      else if (field.type !== 'checkbox' && field.type !== 'radio' || field.checked) {
          serialized.push(encodeURIComponent(field.name) + "=" + encodeURIComponent(field.value));
        }
    }

    return serialized.join('&');
  }

  ;
  /**
   * Polyfill closest
   */

  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (selector) {
      var el = this;

      do {
        if (el.matches(selector)) {
          return el;
        }

        ;
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);

      return null;
    };
  }
  /**
   * Polyfill CustomEvent for IE
   */


  (function () {
    if (typeof window.CustomEvent === 'function') {
      return false;
    }

    function CustomEvent(event, params) {
      params = params || {
        bubbles: false,
        cancelable: false,
        detail: null
      };
      var evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      return evt;
    }

    window.CustomEvent = CustomEvent;
  })();
  /**
   * Validate Constructor
   * @param {Object} form The form element
   * @param {Object} options User options
   */


  function Validate(form, options) {
    // Merge defaults and user options
    this.defaults = defaults;
    this.settings = deepExtend({}, this.defaults, options); // Elements

    this.form = form;
    this.controls = form.querySelectorAll(this.settings.fieldControlSelector);
    this.recaptchaRendered = false;
    this.submitButton = null;

    if (!document.querySelector(this.settings.alertList)) {
      console.warn('Validate.js requires an \'alert list\' element to correctly add reCAPTCHA alerts, please check your configuration.');
    }

    if (!document.querySelector(this.settings.alertTemplate)) {
      console.warn('Validate.js requires an \'alert template\' element to correctly add reCAPTCHA alerts, please check your configuration.');
    }

    if (this.settings.recaptchaSiteKey && !this.form.querySelector('.g-recaptcha')) {
      console.warn('This instance of Validate is configured to use reCAPTCHA, but no `.g-recaptcha` element was found. Please check your configuration.');
    }

    this.init();
  }
  /**
   * Validate Prototype
   * @public
   */


  Validate.prototype = {
    init: function init() {
      this.form.setAttribute('novalidate', 'novalidate'); // Setup aria attributes on inputs - TODO: Support DOM changes and
      // automatically update

      for (var i = 0; i < this.controls.length; i++) {
        if (this.controls[i].attributes && this.controls[i].attributes['required']) {
          this.controls[i].setAttribute('aria-required', true);
        }

        if (this.controls[i].closest('.js-field.is-invalid')) {
          this.controls[i].setAttribute('aria-invalid', true);
        } else {
          this.controls[i].setAttribute('aria-invalid', false);
        }
      }

      this.addEventListeners(); // Focus first invalid on initialisation for any existing errors

      this.focusFirstInvalid();
    },
    loadRecaptcha: function loadRecaptcha() {
      var _this = this;

      if (this.settings.recaptchaSiteKey) {
        if (!recaptchaLoaded) {
          window.recaptchaCallback = this.recaptchaCallback;
          window.currentValidateInstance = this;
          getScript('https://www.google.com/recaptcha/api.js?onload=recaptchaCallback&render=explicit', null, function () {
            _this.addAlert(_this.settings.localisation.alerts.recaptchaProblem, 'invalid');
          });
        } else {
          this.recaptchaCallback(this);
        }
      }
    },
    addEventListeners: function addEventListeners() {
      var _this2 = this;

      var firstControlFocus = function firstControlFocus(e) {
        if (e.target && e.target.matches(_this2.settings.fieldControlSelector)) {
          _this2.loadRecaptcha();

          _this2.form.removeEventListener('focus', firstControlFocus, true);
        }
      }; // Use delegated events so we don't have to manage DOM changes and
      // updating event listeners


      this.form.addEventListener('focus', firstControlFocus, true);
      this.form.addEventListener('change', function (e) {
        if (e.target && e.target.matches(_this2.settings.fieldControlSelector)) {
          _this2.validateControl(e.target);
        }
      });
      this.form.addEventListener('submit', function (e) {
        e.preventDefault(); // Update controls NodeList in case form DOM has changed

        _this2.controls = _this2.form.querySelectorAll(_this2.settings.fieldControlSelector);

        for (var i = 0; i < _this2.controls.length; i++) {
          _this2.validateControl(_this2.controls[i]);
        }

        var invalidFields = _this2.form.querySelectorAll("".concat(_this2.settings.fieldSelector, ".is-invalid"));

        if (invalidFields.length) {
          invalidFields[0].querySelector('input, select, textarea').focus();
          submitter = null;
        } else {
          if (e.submitter) {
            submitter = e.submitter;
          }

          if (_this2.settings.recaptchaSiteKey && !_this2.recaptchaRendered) {
            var recaptchaLoadHandler = function recaptchaLoadHandler() {
              _this2.form.dispatchEvent(new Event('submit', {
                cancelable: true
              }));

              _this2.form.removeEventListener('recaptcha_loaded', recaptchaLoadHandler);
            };

            _this2.form.addEventListener('recaptcha_loaded', recaptchaLoadHandler);

            _this2.loadRecaptcha();

            return false;
          }

          var recaptchaEl = _this2.form.querySelector('.g-recaptcha');

          if (_this2.settings.recaptchaSiteKey && recaptchaEl) {
            if (typeof grecaptcha === 'undefined') {
              // If reCAPTCHA isn't present, it's either blocked by the user or there's
              // an issue with the CDN. Handle custom logic here, i.e. show an alert
              // indicating the user may need to try again
              _this2.addAlert(_this2.settings.localisation.alerts.recaptchaProblem, 'invalid');

              return false;
            }

            grecaptcha.execute(parseInt(recaptchaEl.getAttribute('data-recaptcha-id')));
          } else {
            // Doesn't use reCAPTCHA, bypass reCAPTCHA check manually
            _this2.form.dispatchEvent(new CustomEvent('recaptcha_passed'));
          }
        }
      });
      this.form.addEventListener('recaptcha_passed', function () {
        // If the submit button has a name and a value (e.g. if there are
        // multiple submit buttons), create a hidden input and apply its value
        var submitterInput = null;

        if (submitter && submitter.name && submitter.value) {
          submitterInput = document.createElement('input');
          submitterInput.type = 'hidden';
          submitterInput.name = submitter.name;
          submitterInput.value = submitter.value;

          _this2.form.appendChild(submitterInput);
        }

        if (_this2.settings.ajax === true) {
          var request = new XMLHttpRequest();
          var action;

          if (submitter && submitter.formAction) {
            action = submitter.formAction;
          } else {
            action = _this2.form.action || _this2.form.getAttribute('action') || location.href;
          }

          request.open('POST', action, true);
          request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
          request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

          if (submitter && submitter.classList) {
            submitter.classList.add('is-loading');
          }

          request.onload = function () {
            var responseJSON = JSON.parse(request.response);

            if (submitter && submitter.classList) {
              submitter.classList.remove('is-loading');
            }

            if (request.status >= 200 && request.status < 400) {
              // Success handler
              _this2.addAlert(responseJSON.message || _this2.settings.localisation.alerts.submissionSuccess, 'valid'); // Reset form, clear any submitter stuff


              _this2.form.reset();

              submitter = null;

              if (submitterInput) {
                submitterInput.parentNode.removeChild(submitterInput);
              }

              var recaptchaEl = _this2.form.querySelector('.g-recaptcha');

              if (_this2.settings.recaptchaSiteKey && recaptchaEl) {
                grecaptcha.reset(parseInt(recaptchaEl.getAttribute('data-recaptcha-id')));
              }

              if (typeof _this2.settings.ajaxSuccessCallback === 'function') {
                _this2.settings.ajaxSuccessCallback.call(_this2, _this2.form);
              }
            } else {
              // Error handler
              var recaptchaEl = _this2.form.querySelector('.g-recaptcha');

              if (_this2.settings.recaptchaSiteKey && recaptchaEl) {
                grecaptcha.reset(parseInt(recaptchaEl.getAttribute('data-recaptcha-id')));
              }

              _this2.addAlert(responseJSON.message || _this2.settings.localisation.alerts.submissionProblem, 'invalid');

              var errors = responseJSON.errors;

              for (var key in errors) {
                var input = _this2.form.querySelector("[name=\"".concat(key, "\"]"));

                if (input) {
                  var field = input.closest(_this2.settings.fieldSelector);

                  if (field) {
                    _this2.setInvalidState(field, errors[key][0]);
                  }
                }
              }

              _this2.focusFirstInvalid(); // Clear any submitter stuff


              submitter = null;

              if (submitterInput) {
                submitterInput.parentNode.removeChild(submitterInput);
              }

              if (typeof _this2.settings.ajaxErrorCallback === 'function') {
                _this2.settings.ajaxErrorCallback.call(_this2, _this2.form);
              }
            }
          };

          request.onerror = function () {
            _this2.addAlert(_this2.settings.localisation.alerts.submissionProblem, 'invalid');
          };

          request.send(serialize(_this2.form));
        } else {
          // If the submit button has a formaction="" attribute, manually update
          // the form's action here
          if (submitter && submitter.formAction) {
            _this2.form.action = submitter.formAction;
          } // Unset submitter to prevent any weirdness if the form hangs and
          // a separate form is clicked, for example


          submitter = null;

          _this2.form.submit();
        }
      });
      this.form.addEventListener('recaptcha_error', function () {
        _this2.addAlert(_this2.settings.localisation.alerts.recaptchaProblem, 'invalid');
      });
      this.form.addEventListener('recaptcha_expired', function () {
        _this2.addAlert(_this2.settings.localisation.alerts.recaptchaExpired, 'invalid');
      });
      var alerts = document.querySelectorAll(this.settings.alertSelector);

      var _loop = function _loop(i) {
        alerts[i].querySelector('.js-alert-close').addEventListener('click', function () {
          _this2.removeAlert(alerts[i]);
        });
      };

      for (var i = 0; i < alerts.length; i++) {
        _loop(i);
      }
    },
    focusFirstInvalid: function focusFirstInvalid() {
      var invalidFields = this.form.querySelectorAll("".concat(this.settings.fieldSelector, ".is-invalid"));

      if (invalidFields.length) {
        invalidFields[0].querySelector('input, select, textarea').focus();
      }
    },

    /**
     * @param {Object} validateInstance
     */
    recaptchaCallback: function recaptchaCallback(validateInstance) {
      /**
       * This callback needs to be first called from the global scope, so
       * does not have access to the current instance of Validate, so a
       * global variable is set or passed in as argument
       */
      var base;

      if (typeof validateInstance === 'undefined') {
        base = window.currentValidateInstance;
      } else {
        base = validateInstance;
      }

      recaptchaLoaded = true;
      var recaptcha = base.form.querySelector('.g-recaptcha');

      if (typeof grecaptcha !== 'undefined') {
        var widgetId = grecaptcha.render(recaptcha, {
          sitekey: base.settings.recaptchaSiteKey,
          size: 'invisible',
          callback: function callback(token) {
            base.form.dispatchEvent(new CustomEvent('recaptcha_passed'));
          },
          'error-callback': function errorCallback() {
            base.form.dispatchEvent(new CustomEvent('recaptcha_error'));
          },
          'expired-callback': function expiredCallback() {
            base.form.dispatchEvent(new CustomEvent('recaptcha_expired'));
          }
        });
        recaptcha.setAttribute('data-recaptcha-id', widgetId);
        base.recaptchaRendered = true;
      } else {
        base.addAlert(this.settings.localisation.alerts.recaptchaProblem, 'invalid');
      }

      base.form.dispatchEvent(new CustomEvent('recaptcha_loaded')); // Remove global pollution

      window.recaptchaCallback = null;
      window.currentValidateInstance = null;
    },

    /**
     * @param {String} message
     * @param {String} level
     */
    addAlert: function addAlert(message, level) {
      var _this3 = this;

      var alertList = document.querySelector(this.settings.alertList);
      var alert = document.querySelector(this.settings.alertTemplate).cloneNode(true);
      var alertClose = alert.querySelector('.js-alert-close');
      alertList.appendChild(alert);
      alert.classList.add("alert-".concat(level));
      alert.style.display = 'block';

      if (typeof this.settings.animationShowAlert === 'function') {
        this.settings.animationShowAlert.call(this, alert);
      }

      alert.querySelector('.js-alert-message').innerHTML = message;

      if (alertClose) {
        alertClose.addEventListener('click', function () {
          _this3.removeAlert(alert);
        });
      }
    },

    /**
     * @param {Object} alert
     */
    removeAlert: function removeAlert(alert) {
      if (typeof this.settings.animationHideAlert === 'function') {
        this.settings.animationHideAlert.call(this, alert, function () {
          alert.parentElement.removeChild(alert);
        });
      } else {
        alert.parentElement.removeChild(alert);
      }
    },

    /**
     * @param {Object} field
     */
    setValidState: function setValidState(field) {
      var fieldFeedback = field.querySelector(this.settings.fieldFeedbackSelector);
      field.classList.add('is-valid');

      if (typeof this.settings.animationHideFeedback === 'function') {
        this.settings.animationHideFeedback.call(this, fieldFeedback);
      }
    },

    /**
     * @param {Object} field
     * @param {String} message
     */
    setInvalidState: function setInvalidState(field, message) {
      var fieldFeedback = field.querySelector(this.settings.fieldFeedbackSelector);
      field.classList.add('is-invalid');

      if (message) {
        fieldFeedback.innerHTML = message;

        if (typeof this.settings.animationShowFeedback === 'function') {
          this.settings.animationShowFeedback.call(this, fieldFeedback);
        }
      }
    },

    /**
     * @param {Object} control
     */
    validateControl: function validateControl(control) {
      var field = control.closest(this.settings.fieldSelector);
      var validity = control.validity;
      field.classList.remove('is-valid', 'is-invalid'); // Ignore the control if it is hidden

      if (!(control.offsetWidth || control.offsetHeight || control.getClientRects().length)) {
        return;
      }

      if (validity.valid === false || control.getAttribute('data-validate-custom') || control.classList.contains('js-field-multicheck')) {
        var message = false; // Validation object should be checked in an arbitrary order, rather
        // than using a switch statement, so the user is presented with the
        // input problems in a logical order, firstly with required fields:

        if (validity.valueMissing === true) {
          message = this.settings.localisation.validity.valueMissing; // Check email and URL regexes
        } else if (validity.typeMismatch === true && control.getAttribute('type') === 'email') {
          message = this.settings.localisation.validity.typeMismatchEmail;
        } else if (validity.typeMismatch === true && control.getAttribute('type') === 'url') {
          message = this.settings.localisation.validity.typeMismatchUrl; // Check input length
        } else if (validity.tooShort === true) {
          message = this.settings.localisation.validity.tooShort.replace('$0', control.getAttribute('minlength'));
        } else if (validity.tooLong === true) {
          message = this.settings.localisation.validity.tooLong.replace('$0', control.getAttribute('maxlength')); // Check numerical min/max values
        } else if (validity.rangeUnderflow === true) {
          message = this.settings.localisation.validity.rangeUnderflow.replace('$0', control.getAttribute('min'));
        } else if (validity.rangeOverflow === true) {
          message = this.settings.localisation.validity.rangeOverflow.replace('$0', control.getAttribute('max')); // Check against a regex
        } else if (validity.patternMismatch === true) {
          if (control.getAttribute('data-pattern-message')) {
            message = control.getAttribute('data-pattern-message');
          } else {
            message = this.settings.localisation.validity.patternMismatch;
          } // Custom checks if the input is a multicheck

        } else if (control.classList.contains('js-field-multicheck')) {
          var checks = this.form.querySelectorAll("[name=\"".concat(control.name, "\"]"));
          var checkedCounter = 0;

          for (var i = 0; i < checks.length; i++) {
            if (checks[i].checked) {
              ++checkedCounter;
            }
          }

          if (field.getAttribute('data-validate-multicheck-min') && checkedCounter < parseInt(field.getAttribute('data-validate-multicheck-min'))) {
            if (field.getAttribute('data-validate-multicheck-message')) {
              message = field.getAttribute('data-validate-multicheck-message');
            } else if (parseInt(field.getAttribute('data-validate-multicheck-min')) === 1) {
              message = this.settings.localisation.validity.multicheckMissing;
            } else {
              message = this.settings.localisation.validity.multicheckMin.replace('$0', field.getAttribute('data-validate-multicheck-min'));
            }
          } else if (field.getAttribute('data-validate-multicheck-max') && checkedCounter > parseInt(field.getAttribute('data-validate-multicheck-max'))) {
            if (field.getAttribute('data-validate-multicheck-message')) {
              message = field.getAttribute('data-validate-multicheck-message');
            } else {
              message = this.settings.localisation.validity.multicheckMax.replace('$0', field.getAttribute('data-validate-multicheck-max'));
            }
          } // Finally, run a custom function passed as a data attribute (if present)

        } else if (this.settings.customValidations[control.getAttribute('data-validate-custom')](control.value) !== true) {
          if (control.getAttribute('data-validate-custom-message')) {
            message = control.getAttribute('data-validate-custom-message');
          } else {
            message = this.settings.localisation.validity.default;
          }
        } // If a message has been set, the input is invalid


        if (message) {
          control.setAttribute('aria-invalid', true);
          this.setInvalidState(field, message);
        } else {
          control.setAttribute('aria-invalid', false);
          this.setValidState(field);
        }
      } else {
        this.setValidState(field);
      }
    }
  };
  window.Validate = Validate;
})(window, document);
