/*
 * Essential Imports
 */

import 'picturefill';
import 'lazysizes';
import './vendor/ls.object-fit';
import './functions/ga-events';

/*
 * Essentail Fixes
 */

// Preload and No-JS
setTimeout(function () {
    document.documentElement.classList.remove('preload');
    document.documentElement.classList.remove('no-js');
}, 300);

let resizeId, mainHeaderELe, mainWrapperEle;
mainHeaderELe = document.getElementById('main-header');
mainWrapperEle = document.getElementById('main-wrapper');
mainWrapperEle.style.marginTop = `${mainHeaderELe.offsetHeight}px`;

window.addEventListener('resize', function () {
    clearTimeout(resizeId);
    document.documentElement.classList.add('preload');
    resizeId = setTimeout(function () {
        document.documentElement.classList.remove('preload');
    }, 300);

    // TEST - may cause little lag when resizeing due to layout shift
    mainWrapperEle.style.marginTop = `${mainHeaderELe.offsetHeight}px`;
});

// Fix NodeList.forEach in IE11
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/*
 * Accordion
 */

import Accordion from './functions/accordion';
const accordion = new Accordion();

/*
 * AppendAround
 */

import appendAround from './functions/append-around';

window.addEventListener('load', function () {
    appendAround();
});

/*
 * FlashMessage
 */

import {
    default as FlashMessage,
    createAlertMsg,
    dismissMessage,
} from './functions/flash-message';
const flash_message = new FlashMessage();

/*
 * Forms
 */

// FormValidation
import './functions/form-validation';
document.querySelectorAll('.form.js-validate').forEach((form) => {
    if (form.querySelector('.g-recaptcha')) {
        recaptcha = document
            .querySelector("meta[name='recaptcha_site_key']")
            .getAttribute('content');
    } else {
        recaptcha = false;
    }

    let alertMessages = JSON.parse(form.dataset.alerts);
    let submitBtn = form.querySelector('button[type="submit"]');

    if (typeof submitBtn != 'undefined' && submitBtn != null) {
        submitBtn.disabled = false;
    }

    new Validate(form, {
        recaptchaSiteKey: recaptcha,
        fieldFeedbackSelector: '.js-field-feedback span',
        ajax: true,
        ajaxSuccessCallback: () => {
            enableSubmitBtn();
            dismissFormAlerts();
        },
        ajaxErrorCallback: () => {
            enableSubmitBtn();
            dismissFormAlerts();
        },
        localisation: {
            validity: {
                valueMissing: 'This is a required field',
                typeMismatchEmail: 'Please enter a valid email address',
                typeMismatchUrl: 'Please enter a valid website address',
                tooShort: 'Must be a minimum of $0 characters',
                tooLong: 'Must be a maximum of $0 characters',
                rangeUnderflow: 'Must be after $0',
                rangeOverflow: 'Must not before $0',
                patternMismatch: 'Please match the format requested',
                default: 'There was a problem with the inputted data',
                multicheckMissing: 'Please select an option',
                multicheckMin: 'Please select a minimum of $0 options',
                multicheckMax: 'Please select a maximum of $0 options',
            },
            alerts: {
                recaptchaExpired: 'reCAPTCHA has expired, please try again',
                recaptchaProblem:
                    'There is a problem with reCAPTCHA, please try again',
                submissionProblem: alertMessages.invalid,
                submissionSuccess: alertMessages.valid,
            },
        },
    });

    form.addEventListener('submit', function () {
        disableSubmitBtn();
        // Focus invalid field behaviour
        let invalidField = form.querySelector('.js-field.is-invalid');
        if (invalidField) {
            scrollToElem(invalidField);
            enableSubmitBtn();
            dismissFormAlerts();
        }
    });

    // Enable Submit Button & Hide ajax loader
    function enableSubmitBtn() {
        if (typeof submitBtn != 'undefined' && submitBtn != null) {
            submitBtn.disabled = false;
            submitBtn.classList.remove('is_ajax-loading');
        }
    }

    // Disable Submit Button & Show ajax loader
    function disableSubmitBtn() {
        if (typeof submitBtn != 'undefined' && submitBtn != null) {
            submitBtn.disabled = true;
            submitBtn.classList.add('is_ajax-loading');
        }
    }

    // Dismiss Alerts from Form Validator
    function dismissFormAlerts() {
        document
            .querySelectorAll(
                '.flash-messages .alert-invalid, .flash-messages .alert-valid'
            )
            .forEach((alert) => {
                setTimeout(() => {
                    dismissMessage(alert);
                }, 7200);
            });
    }
});

// In-field Labels
document.querySelectorAll('.js-infield').forEach((field) => {
    field.addEventListener('keyup', (event) => {
        field.setAttribute('value', field.value);
    });
});

/*
 * Flatpickr
 */
import flatpickr from './vendor/flatpickr';
if (
    typeof document.querySelector('.flatpickr') != 'undefined' &&
    document.querySelector('.flatpickr') != null
) {
    document.querySelectorAll('.flatpickr').forEach((el) => {
        flatpickr(el, {
            enableTime: true,
            dateFormat: 'Y-m-d H:i',
            minTime: '9:00',
            maxTime: '17:00',
            minDate: 'today',
            disable: [
                function (date) {
                    // Disable saturday & sunday
                    return date.getDay() === 0 || date.getDay() === 6;
                },
            ],
            locale: {
                firstDayOfWeek: 1, // start week on Monday
            },
        });
    });
}

/*
 * Support Functions
 */

import { scrollToElem } from './functions/support';

/*
 * GoTo Elem
 */

if (
    typeof document.querySelector('.js-goto_elem') != 'undefined' &&
    document.querySelector('.js-goto_elem') != null
) {
    document.querySelectorAll('.js-goto_elem').forEach((el) => {
        let elem = document.getElementById(el.dataset.elem);

        if (elem) {
            el.addEventListener('click', (event) => {
                scrollToElem(elem);
            });
        }
    });
}

// On Page Load
if (window.location.hash) {
    let elem = document.getElementById(window.location.hash.substring(1));

    if (elem) scrollToElem(elem);
}

/*
 * Modal Window
 */

import {
    default as ModalWindow,
    toggleModalWindow,
    checkModalHeight,
} from './functions/modal-window';
const modal_window = new ModalWindow();

/*
 * Video Modal
 */
if (
    typeof document.querySelector('.js-video_inject') != 'undefined' &&
    document.querySelector('.js-video_inject') != null
) {
    document.querySelectorAll('.js-video_inject').forEach((el) => {
        window.addEventListener('click', (event) => {
            if (event.target.classList.contains('js-video_inject')) {
                let videoContent = el.dataset.video;
                let videoModal = document.querySelector('.modal-video');
                let videoSwap = videoModal.querySelector('.video-swap');
                videoSwap.innerHTML = videoContent;
            }
        });
    });
    document.querySelector('.modal-video').addEventListener('click', (elem) => {
        document.querySelector('.video-swap').innerHTML = '';
    });
}

/*
 * Navigation (Primary / Top - Mobile)
 */

if (
    typeof document.querySelector('.nav-mobile .nav-sublist_trigger') !=
        'undefined' &&
    document.querySelector('.nav-mobile .nav-sublist_trigger') != null
) {
    document
        .querySelectorAll('.nav-mobile .nav-sublist_trigger')
        .forEach((el) => {
            el.addEventListener('click', (event) => {
                event.currentTarget.nextElementSibling.classList.toggle('open');
            });
        });
}

/*
 * StickyHeader
 */

import StickyHeader from './functions/sticky-header';
const sticky_header = new StickyHeader();

/*
 * Swiper
 */

import Swiper from './vendor/swiper';
document.querySelectorAll('.js-swiper').forEach((swiper) => {
    let swiperID = swiper.id;
    let params = swiper.dataset.swiper;
    let mySwiper = new Swiper(swiper, JSON.parse(params));

    // Swiper Height
    document.addEventListener('lazyloaded', () => {
        if (swiper.dataset.autoheight) mySwiper.updateAutoHeight();
    });
});

// Swiper GOTO
document.querySelectorAll('.js-swiper_goto').forEach((goto) => {
    goto.addEventListener('click', (event) => {
        event.currentTarget.blur();

        let swiperSlider = JSON.parse(goto.dataset.slider);
        let swiperSlide = goto.dataset.slide;

        for (var i = 0; i < swiperSlider.length; i++) {
            let mySwiper = document.getElementById(swiperSlider[i]).swiper;
            mySwiper.slideTo(swiperSlide);
        }
    });
});

/*
 * Tabbing
 */

const handleFirstTab = (e) => {
    if (e.keyCode === 9) {
        document.body.classList.add('has-tabbing-user');

        window.removeEventListener('keydown', handleFirstTab);
        window.addEventListener('mousedown', handleMouseDownOnce);
    }
};

const handleMouseDownOnce = () => {
    document.body.classList.remove('has-tabbing-user');

    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
};

window.addEventListener('keydown', handleFirstTab);

/*
 * Video
 */

import Video from './functions/video';
const video = new Video();

/*
 * Viewport
 */

// Define our viewportWidth variable
let viewportWidth;

// Set/update the viewportWidth value
let setViewportWidth = function () {
    viewportWidth = window.innerWidth || document.documentElement.clientWidth;
};

// Log the viewport width into the console
let logWidth = function () {
    if (
        typeof document.querySelector('.js-viewport_swap') != 'undefined' &&
        document.querySelector('.js-viewport_swap') != null
    ) {
        document.querySelectorAll('.js-viewport_swap').forEach((el) => {
            let originalElement = el;
            let originalElementClone = el.cloneNode(true);
            let swapElement = document.getElementById(el.dataset.swap);
            let swapElementClone = swapElement.cloneNode(true);

            if (viewportWidth > 980) {
                if (originalElementClone.classList.contains('is-swapped')) {
                    swapElement.parentNode.replaceChild(
                        originalElementClone,
                        swapElement
                    );
                    originalElement.parentNode.replaceChild(
                        swapElementClone,
                        originalElement
                    );

                    originalElementClone.classList.remove('is-swapped');
                }
            } else {
                if (!originalElementClone.classList.contains('is-swapped')) {
                    swapElement.parentNode.replaceChild(
                        originalElementClone,
                        swapElement
                    );
                    originalElement.parentNode.replaceChild(
                        swapElementClone,
                        originalElement
                    );

                    originalElementClone.classList.add('is-swapped');
                }
            }
        });
    }
};

// Set our initial width and log it
setViewportWidth();
logWidth();

// On resize events, recalculate and log
window.addEventListener(
    'resize',
    function () {
        setViewportWidth();
        logWidth();
    },
    false
);

/*
 * Cookie Controller
 */

import './functions/cookie-controller';
const cookieController = new CookieController({
    layout: 'modal',
    requiredCookies: ['PHPSESSID'],
    tooltipContent:
        'These cookies are necessary for the website to function and cannot be switched off in our systems.',
    websiteName: 'Breakwater IT',
    optionalGroups: [
        {
            name: 'functional',
            label: 'Functional',
            description:
                'Functionality cookies allow the website to remember the user’s site preferences and choices they make on the site when using features such as maps. Functionality cookies can include first party, third party, persistent or session cookies. Similar to strictly necessary cookies, functionality cookies are used to provide a service on the website.',
            cookies: ['CONSENT'],
            defaultState: 'off',
            recommendedState: 'on',
            onAllow: function () {
                dataLayer.push({
                    event: 'ccFunctional',
                    allow: 'allow',
                });
            },
            onDisallow: function () {
                dataLayer.push({
                    event: 'ccFunctional',
                    allow: '',
                });
            },
        },
        {
            name: 'analytics',
            label: 'Analytics and Performance',
            description:
                'These cookies allow us to count visits and traffic sources, so we can measure and improve the performance of our site. This includes cookies from third-party analytics services. They help us know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies, we will not know when you have visited our site.',
            cookies: ['_ga', '_gid'],
            defaultState: 'off',
            recommendedState: 'on',
            onAllow: function () {
                dataLayer.push({
                    event: 'ccAnalytics',
                    allow: 'allow',
                });
            },
            onDisallow: function () {
                dataLayer.push({
                    event: 'ccAnalytics',
                    allow: '',
                });
            },
        },
        {
            name: 'marketing',
            label: 'Marketing',
            description:
                'These cookies track your online activity to help deliver more relevant advertising or to limit how many times you see an ad. These cookies can share that information with other organizations or advertisers. These are persistent cookies and can be set by third party websites and do things like measure how you view videos that are on our website.',
            cookies: [],
            defaultState: 'off',
            recommendedState: 'on',
            onAllow: function () {
                dataLayer.push({
                    event: 'ccMarketing',
                    allow: 'allow',
                });
            },
            onDisallow: function () {
                dataLayer.push({
                    event: 'ccMarketing',
                    allow: '',
                });
            },
        },
    ],
});

/*
 * GA Events
 */

import './functions/ga-events';

/*
 * Google Maps
 */

window.initMap = function () {
    if (
        typeof document.querySelector('.js-map') != 'undefined' &&
        document.querySelector('.js-map') != null
    ) {
        document.querySelectorAll('.js-map').forEach((el) => {
            let map;
            let mapinfo = JSON.parse(el.dataset.mapinfo);
            map = new google.maps.Map(el, {
                center: { lat: mapinfo.lat, lng: mapinfo.lng },
                zoom: mapinfo.zoom,
                streetViewControl: false,
                mapTypeControl: false,
            });

            let location = { lat: mapinfo.lat, lng: mapinfo.lng };

            new google.maps.Marker({
                position: location,
                label: { text: mapinfo.name },
                map: map,
            });
        });
    }
};

if (
    typeof document.querySelector('.js-map') != 'undefined' &&
    document.querySelector('.js-map') != null
) {
    const gmap_key = document
        .querySelector("meta[name='google_maps_api_key']")
        .getAttribute('content');
    if (gmap_key) {
        const gscript = document.createElement('script');
        gscript.src =
            'https://maps.googleapis.com/maps/api/js?key=' +
            gmap_key +
            '&callback=initMap&libraries=&v=weekly';
        gscript.id = 'googleMaps';
        document.body.appendChild(gscript);
    }
}

/*
 * Team Members
 */
let imgData = new Array();
imgData[0] = 'one';
imgData[1] = 'two';
imgData[2] = 'three';
imgData[3] = 'four';
imgData[4] = 'five';
imgData[5] = 'six';
imgData[6] = 'seven';
imgData[7] = 'eight';
imgData[8] = 'nine';
imgData[9] = 'two';
imgData[10] = 'ten';

function offset(element) {
    if (!element.getClientRects().length) {
        return { top: 0, left: 0 };
    }

    let rect = element.getBoundingClientRect();
    let win = element.ownerDocument.defaultView;
    return {
        top: rect.top + win.pageYOffset,
        left: rect.left + win.pageXOffset,
    };
}
function changeTeamMemberImage(coords, element) {
    let position = offset(element);
    let halfWidth = element.offsetWidth / 2;
    let halfHeight = element.offsetHeight / 2;
    let halfX = position.left + halfWidth;
    let halfY = position.top + halfHeight;
    let relX = coords.x - halfX;
    let relY = coords.y - halfY;
    let distance = Math.sqrt(relX * relX + relY * relY);
    if (distance > halfWidth) {
        let sign = relX > 0 ? 1 : -1;
        let angle = 64 * Math.acos(-relY / distance);
        angle *= sign;
        let imgID = Math.round(angle / 45) + 5;
        let dataReq = element.getAttribute('data-' + imgData[imgID]);
        if (dataReq) {
            element.setAttribute('src', dataReq);
        }
    } else {
        let dataReq = element.getAttribute('data-' + imgData[10]);
        if (dataReq) {
            element.setAttribute('src', dataReq);
        }
    }
}

let teamMembers = document.querySelectorAll(
    '.team-grid .team-grid-image, .team-member_bg .team-member_bg-image, .team-member_page .page_bg-image'
);

if (teamMembers.length > 0) {
    document.addEventListener('mousemove', (a) => {
        let i = { x: a.pageX, y: a.pageY };
        (distance = Math.sqrt(i.x * i.x + i.y * i.y)),
            distance > 15 &&
                teamMembers.forEach((el) => {
                    changeTeamMemberImage(i, el);
                });
    });

    let preloadImgs = [];
    teamMembers.forEach((el) => {
        imgData.forEach((i) => {
            let img = new Image();
            img.src = el.getAttribute('data-' + i);
            preloadImgs.push(img);
        });
    });
}

/*
 * Stats
 */

let statsGrid = document.querySelector('.stats_grid');
let statsEventFired = false;

function isScrolledIntoView(el) {
    var rect = el.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;

    return isVisible;
}

function incStatValue(ele) {
    ele.firstChild.innerText = 0;
    // not the most elegent
    let val = ele.getAttribute('data-percentage');
    let hasDecimal = val % 1 === 0 ? false : true;
    let decimal = val % 1;
    let count = 0;

    var i = 1;
    function loopInc() {
        setTimeout(function () {
            i++;
            count++;
            ele.firstChild.innerText = count;
            if (i < parseInt(val) + 1 - decimal) {
                loopInc();
            } else {
                if (hasDecimal)
                    ele.firstChild.innerText =
                        parseInt(ele.firstChild.innerText) + decimal;
            }
        }, 25);
    }
    loopInc();
}

window.addEventListener(
    'scroll',
    () => {
        if (!statsEventFired) {
            if (typeof statsGrid != 'undefined' && statsGrid != null) {
                if (isScrolledIntoView(statsGrid)) {
                    let stats = document.querySelectorAll(
                        '.stat_grid-item_svg'
                    );
                    if (stats.length > 0) {
                        // Animate svgs
                        stats.forEach((svg) => {
                            let roundPercent = Math.round(
                                svg.getAttribute('data-percentage')
                            );
                            let roundCircum = svg
                                .querySelector('.stat-front')
                                .getTotalLength();
                            let roundDraw = (roundPercent * roundCircum) / 100;
                            svg.querySelector(
                                '.stat-front'
                            ).style.strokeDasharray = roundDraw + ' 999';
                        });
                        // Inc the number
                        let statTextArr =
                            document.querySelectorAll('[data-percentage]');
                        statTextArr.forEach((statText) => {
                            incStatValue(statText);
                        });

                        statsEventFired = true;
                    }
                }
            }
        }
    },
    false
);

// Desktop nav search dropdown
var toggleSearchBtn = document.getElementById('deskSearchToggleBtn');
var deskSearchDropdown = document.getElementById('deskSearchDropdown');
toggleSearchBtn.addEventListener('click', (e) => {
    deskSearchDropdown.classList.toggle('open');
});

// Comparison Tool
let comTool = document.querySelector('.pb-block_comparison-tool');
if (typeof comTool != 'undefined' && comTool != null) {
    let com_checkboxes = comTool.querySelectorAll('.com-check');
    let com_checkboxes_total = 0;

    com_checkboxes.forEach((cb) => {
        com_checkboxes_total += parseInt(cb.dataset.weight);
    });

    setChecked();
    set_com_percentage();

    com_checkboxes.forEach((com_cb) => {
        com_cb.addEventListener('click', () => {
            set_com_percentage();
        });
    });

    function set_com_percentage() {
        let com_checkboxes_checked =
            comTool.querySelectorAll('.com-check:checked');
        let com_checkboxes_checked_total = 0;

        com_checkboxes_checked.forEach((cb) => {
            com_checkboxes_checked_total += parseInt(cb.dataset.weight);
        });

        let com_percentage = Math.round(
            (com_checkboxes_checked_total / com_checkboxes_total) * 100
        );
        let metric_bg = document.getElementById('metric_bg');
        let m_percent = document.getElementById('metric-percentage');
        let old_percent = m_percent.innerHTML
            ? parseInt(m_percent.innerHTML)
            : 0;

        animateValue(m_percent, old_percent, com_percentage, 500);
        document.querySelector('.metric-container svg').style.transform =
            'rotate(' + com_percentage * 2 + 'deg)';

        metric_bg.classList.remove(...metric_bg.classList);
        if (com_percentage < 60) {
            metric_bg.classList.add('fill_02-01');
        } else if (com_percentage >= 60 && com_percentage < 90) {
            metric_bg.classList.add('fill_02-03');
        } else {
            metric_bg.classList.add('fill_03-03');
        }

        let basic_percentage = getSectionPercentage('.top-options');
        animateValue(
            document.getElementById('com_top_score'),
            parseInt(document.getElementById('com_top_score').innerHTML),
            basic_percentage,
            500
        );

        let pro_percentage = getSectionPercentage('.bottom-options');
        if (typeof pro_percentage != 'undefined' && pro_percentage != null) {
            if (
                document.getElementById('com_bottom_score') != 'undefined' &&
                document.getElementById('com_bottom_score') != null
            ) {
                animateValue(
                    document.getElementById('com_bottom_score'),
                    parseInt(
                        document.getElementById('com_bottom_score').innerHTML
                    ),
                    pro_percentage,
                    500
                );
            }
        }

        setURLParams();
    }

    let btns = document.querySelectorAll('.option-btn');
    btns.forEach((b) => {
        b.addEventListener('click', (e) => {
            e.target.closest('.option').classList.toggle('show');
        });
    });

    function animateValue(obj, start, end, duration) {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min(
                (timestamp - startTimestamp) / duration,
                1
            );
            obj.innerHTML = Math.floor(progress * (end - start) + start);
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    function getSectionPercentage(section) {
        let total = 0;
        let checked_total = 0;

        document.querySelectorAll(section + ' .com-check').forEach((cb) => {
            total += parseInt(cb.dataset.weight);
        });

        document
            .querySelectorAll(section + ' .com-check:checked')
            .forEach((cb) => {
                checked_total += parseInt(cb.dataset.weight);
            });

        return Math.round((checked_total / total) * 100);
    }

    function setChecked() {
        let tp_params = [];
        let bp_params = [];

        new URL(window.location).searchParams.forEach((val, key) => {
            if (key == 'tp') {
                tp_params = val.split('~');
            }

            if (key == 'bp') {
                bp_params = val.split('~');
            }
        });

        if (tp_params.length || bp_params.length) {
            document.querySelectorAll('.com-check').forEach((cb) => {
                cb.checked = false;
            });
        }

        let top_cbs = document.querySelectorAll('.top-options .com-check');
        tp_params.forEach((param) => {
            if (param != '' && param != undefined) {
                top_cbs[parseInt(param) - 1].checked = true;
            }
        });

        let bottom_cbs = document.querySelectorAll(
            '.bottom-options .com-check'
        );
        if (bottom_cbs.length) {
            bp_params.forEach((param) => {
                if (param != '' && param != undefined) {
                    bottom_cbs[parseInt(param) - 1].checked = true;
                }
            });
        }
    }

    function setURLParams() {
        const url = new URL(
            window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname
        );

        let d = /\d+/;

        let top_params = [];
        document
            .querySelectorAll('.top-options .com-check:checked')
            .forEach((cb) => {
                let s = cb.id;
                top_params.push(s.match(d));
            });
        top_params = top_params.join('~');
        url.searchParams.set('tp', top_params);

        let bottom_params = [];
        document
            .querySelectorAll('.bottom-options .com-check:checked')
            .forEach((cb) => {
                let s = cb.id;
                bottom_params.push(s.match(d));
            });
        bottom_params = bottom_params.join('~');
        url.searchParams.set('bp', bottom_params);

        if (history.pushState) {
            window.history.pushState({}, '', url);
        }
    }
}

let com_email_btn = document.querySelector('.email_btn .email_link');
if (typeof com_email_btn != 'undefined' && com_email_btn != null) {
    com_email_btn.addEventListener('click', () => {
        document.getElementById('comparison_url').value = window.location.href;
    });
}
