'use strict'

import { lockScroll, unlockScroll } from './support';

export default class ModalWindow {
    constructor() {
        this.listeners();
    }

    listeners() {
        document.addEventListener('click', this.toggleModalWindow, false);
        document.addEventListener('keydown', this.keyDown);
        document.addEventListener('click', this.backdropClose, false);

        if(typeof(document.querySelector('.js-modal_wrapper')) != 'undefined' && document.querySelector('.js-modal_wrapper') != null) {
            document.querySelectorAll('.js-modal_wrapper').forEach(modal => {

                // Modal contains invalid form
                if (!modal.classList.contains('open')) {
                    let formsInvalid = modal.querySelectorAll('.is-invalid');
                    if (formsInvalid.length > 0) {
                        const event = new Event('formsInvalid');
                        modal.querySelector('.modal-close').addEventListener('formsInvalid', this.toggleModalWindow, false);
                        modal.querySelector('.modal-close').dispatchEvent(event);
                    }
                }

                // Modal contains flash message
                if (!modal.classList.contains('open')) {
                    let flashMsg = modal.querySelectorAll('.flash-message');
                    if (flashMsg.length > 0) {
                        const event = new Event('flashMsg');
                        modal.querySelector('.modal-close').addEventListener('flashMsg', this.toggleModalWindow, false);
                        modal.querySelector('.modal-close').dispatchEvent(event);
                    }
                }
            });
        }
    }

    keyDown(el) {
        if(27 === el.keyCode) {
            document.querySelectorAll('.js-modal_wrapper').forEach(el => {
                el.classList.contains('open');
                ModalWindow.closeModalWindow();
            });
        }
    }

    backdropClose(el) {
        document.querySelectorAll('.js-modal_wrapper').forEach(elem => {
            if(elem == el.target && elem.classList.contains('open'))
                ModalWindow.closeModalWindow();
        });
    }

    toggleModalWindow(el) {
        var target = el.target;

        if (el.isTrusted) {
            for (var appTarget = el.target; appTarget && appTarget != this; appTarget = appTarget.parentNode) {
                if (appTarget.matches('.js-modal_trigger')) {
                    target = appTarget;
                }
            }
        }

        if (target.matches('.js-modal_trigger')) {
            target.blur();
            toggleModalWindow(target.dataset.modal);
        }
    }

    static closeModalWindow() {
        document.querySelectorAll('.js-modal_wrapper.open').forEach(el => {
            el.classList.remove('open');
            unlockScroll();
        });
    }
}

// Toggle Modal Window
export function toggleModalWindow(modalID) {
    lockScroll();

    let modal = document.getElementById(modalID);

    document.querySelectorAll('.js-modal_wrapper.open').forEach(el => {
        if (el != modal) {
            el.classList.remove('open');
            unlockScroll();
        }
    });

    if(modal.classList.contains('open')) {
        unlockScroll();
        modal.classList.toggle('open');
    } else {
        lockScroll();
        modal.classList.toggle('open');
    }

    checkModalHeight(modal);
    window.addEventListener('resize', function(){ checkModalHeight(modal) });
}

// Check Modal Height
export function checkModalHeight(modal) {
    let heightBody = window.innerHeight;
    let heightModalContent = modal.querySelector('.js-modal_content').offsetHeight;

    if (heightModalContent >= heightBody)
        modal.classList.add('fixedHeight');
    else
        modal.classList.remove('fixedHeight');
}
