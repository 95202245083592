import Cookies from "js-cookie";

((window, document) => {
    'use strict'

    const defaults = {
        animSpeed: 300,
        bannerContent: '',
        cookiePolicyBtn: 'Cookie Policy',
        cookiePolicyLink: '/cookie-policy/',
        creditContent: 'Powered by the <a href="https://gritdigital.co.uk/" target="_blank" rel="noopener">GRIT Cookie Controller</a>',
        cssNamespace: 'cc',
        expiry: 90,
        introContent: '',
        introTitle: 'This site uses cookies to store information about your computer.',
        labelAcceptRecommendedSettings: 'Accept Recommended Settings',
        labelBack: 'Back',
        labelClose: 'Close',
        labelCookies: 'Cookies',
        labelOff: 'Off',
        labelOn: 'On',
        labelSaveAndExit: 'Save &amp; Exit',
        layout: 'banner', // || 'drawer' || 'banner'
        learnMoreBtn: 'Cookie Settings',
        necessaryDescription: '',
        necessaryTitle: 'Strictly Necessary',
        optionalGroups: [],
        prependDrawer: document.querySelector('body'),
        tooltipContent: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Explicabo, ea. Animi perferendis architecto, sequi magnam ad voluptate reiciendis ipsum itaque, veniam, nostrum aut atque ullam iste delectus quis! Voluptatum, fugiat.',
        tooltipTitle: 'Why do I need to do this?',
        websiteName: '[SITE_NAME]',
    }

    /**
     * Polyfills
     */
    if (typeof window.CustomEvent !== 'function') {
        window.CustomEvent = (event, params) => {
            params = params || { bubbles: false, cancelable: false, detail: null }
            const evt = document.createEvent('CustomEvent')
            evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
            return evt
        }
    }

    if (!Element.prototype.matches) {
        Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
    }

    if (!Element.prototype.closest) {
        Element.prototype.closest = function (selector) {
            let el = this

            do {
                if (el.matches(selector)) {
                    return el
                }

                el = el.parentElement || el.parentNode
            } while (el !== null && el.nodeType === 1)

            return null
        }
    }

    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach
    }

    if (typeof Object.assign !== 'function') {
        // Must be writable: true, enumerable: false, configurable: true
        Object.defineProperty(Object, 'assign', {
            value: function assign (target, varArgs) {

                if (target === null || target === undefined) {
                    throw new TypeError('Cannot convert undefined or null to object')
                }

                var to = Object(target)

                for (var index = 1; index < arguments.length; index++) {
                    var nextSource = arguments[index]

                    if (nextSource !== null && nextSource !== undefined) {
                        for (var nextKey in nextSource) {
                            // Avoid bugs when hasOwnProperty is shadowed
                            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                                to[nextKey] = nextSource[nextKey]
                            }
                        }
                    }
                }
                return to
            },
            writable: true,
            configurable: true,
        })
    }

    /**
     * Helpers
     */
    function innerHeight (el) {
        const style = getComputedStyle(el)
        return el.offsetHeight - parseInt(style.paddingTop) - parseInt(style.paddingBottom) - parseInt(style.borderTopWidth) - parseInt(style.borderBottomWidth)
    }

    function outerHeight (el) {
        const style = getComputedStyle(el)
        let height = el.offsetHeight

        height += parseInt(style.marginTop) + parseInt(style.marginBottom)
        return height
    }

    function CookieController (options) {
        this.settings = Object.assign(defaults, options)
        this._defaults = defaults

        this.init()
    }

    CookieController.prototype = {
        init () {
            if (this.settings.introContent == '') {
                this.settings.introContent = 'Some of these cookies are essential, while others help us to improve your experience by providing insights into how the site is being used. For more detailed information on the cookies we use, please check our <a href="' + defaults.cookiePolicyLink + '" target="_blank">' + defaults.cookiePolicyBtn + '</a>.'
            }

            if (this.settings.necessaryDescription == '') {
                this.settings.necessaryDescription = 'These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site may not work then. For more detailed information on the cookies we use, please check our <a href="' + defaults.cookiePolicyLink + '" target="_blank">' + defaults.cookiePolicyBtn + '</a>.'
            }

            if ((this.settings.layout == 'banner' || this.settings.layout == 'drawer') && this.settings.bannerContent == '') {
                this.settings.bannerContent = 'We use some essential cookies to make this website work.<br />We\'d like to set additional cookies to understand how you use ' + defaults.websiteName + ', remember your settings and improve our website.<br />We also use cookies set by other sites to help us deliver content from their services.'
            }

            this.build()
            this.addEventListeners()
            this.checkAllowed()

            if (Cookies.get('ccDismissed') !== 'true') {
                switch (this.settings.layout) {
                    case 'modal':
                        this.open()
                        break

                    case 'drawer': {
                        const drawer = document.querySelector('#cc-banner')

                        drawer.style.display = 'block'
                        drawer.style.height = 0
                        drawer.style.overflow = 'hidden'
                        drawer.style.transition = `height ${this.settings.animSpeed}ms ease-in-out`
                        drawer.style.height = `${drawer.scrollHeight}px`

                        const drawerTransitionEnd = () => {
                            drawer.removeEventListener('transitionend', drawerTransitionEnd)
                            drawer.style.height = null
                        }

                        drawer.addEventListener('transitionend', drawerTransitionEnd)

                        break
                    }
                    case 'banner':
                        document.querySelector('#cc-banner').offsetHeight
                        document.querySelector('#cc-banner').classList.add('is-active')
                }
            }
        },

        build () {
            let html =
                `<button type="button" class="${this.settings.cssNamespace}-toggle" id="cc-toggle">
                <svg class="${this.settings.cssNamespace}-icon" viewBox="0 0 20 20"><path d="M20,11.2V8.7l-3-1.2c-0.1-0.2-0.2-0.5-0.3-0.7l1.2-3L16.2,2l-3,1.2C13,3.1,12.7,3.1,12.5,3l-1.3-3H8.7L7.5,3 C7.2,3,7,3.1,6.8,3.3l-3-1.2L2,3.8l1.2,2.9C3.1,7,3,7.3,2.9,7.5L0,8.8v2.5l2.9,1.2C3,12.7,3.1,13,3.2,13.2l-1.2,3L3.8,18l3-1.2 C7,16.9,7.3,17,7.5,17l1.3,3h2.5l1.2-3c0.2-0.1,0.5-0.2,0.7-0.3l3,1.2l1.8-1.8l-1.2-3c0.1-0.2,0.2-0.5,0.3-0.7L20,11.2z M10,13.8 c-2.1,0-3.8-1.7-3.8-3.8S7.9,6.2,10,6.2s3.8,1.7,3.8,3.8S12.1,13.8,10,13.8z"/></svg>
                <span class="${this.settings.cssNamespace}-toggle-label">${this.settings.learnMoreBtn}</span>
            </button>
            <div class="${this.settings.cssNamespace}" id="cc">
                <div class="${this.settings.cssNamespace}-body" id="cc-body">
                    <button type="button" class="${this.settings.cssNamespace}-close  js-cc-close">
                        ${this.settings.labelClose}
                        <svg class="${this.settings.cssNamespace}-icon" viewBox="0 0 17 17"><path d="M15.4 0L8.5 6.9 1.7.1.1 1.7l6.8 6.8L0 15.4 1.6 17l6.9-6.9 7 6.9 1.5-1.5-6.9-7L17 1.6z"/></svg>
                    </button>
                    <button type="button" class="${this.settings.cssNamespace}-back  js-cc-back" aria-label="${this.settings.labelBack}">
                        <span class="visually-hidden">${this.settings.labelBack}</span>
                        <svg class="${this.settings.cssNamespace}-icon"  viewBox="0 0 14.1 8.4"><path d="M12.8 0L7.1 5.7 1.4 0 0 1.4l7.1 7 7-7z"/></svg>
                    </button>
                    <div class="${this.settings.cssNamespace}-content  ${this.settings.cssNamespace}-step-1  js-cc-modal-content" id="cc-step-1">
                        <h2>${this.settings.introTitle}</h2>
                        <p>${this.settings.introContent}</p>

                        <p class="${this.settings.cssNamespace}-pinch">
                            <button type="button" class="${this.settings.cssNamespace}-btn  ${this.settings.cssNamespace}-btn-tick  ${this.settings.cssNamespace}-btn-accept  js-cc-tick" data-cc-accept-recommended="true">
                                ${this.settings.labelAcceptRecommendedSettings}
                                <svg class="${this.settings.cssNamespace}-icon" viewBox="0 0 24 17.9"><path d="M24 2.3L21.7 0 8.4 13.3 2.3 7.2 0 9.5l6.8 6.8 1.6 1.6 1.6-1.6z"/></svg>
                            </button>
                            <button type="button" class="${this.settings.cssNamespace}-btn  ${this.settings.cssNamespace}-btn-accept  js-cc-learn-more">${this.settings.learnMoreBtn}</button>
                        </p>
                    </div>
                    <div class="${this.settings.cssNamespace}-content  js-cc-modal-content" id="cc-step-2">
                        <div class="${this.settings.cssNamespace}-split">
                            <h2 class="${this.settings.cssNamespace}-split-title">${this.settings.labelCookies}</h2>
                            <div class="${this.settings.cssNamespace}-tooltip  js-cc-tooltip">
                                <button type="button" class="${this.settings.cssNamespace}-tooltip-toggle  js-cc-tooltip-toggle">${this.settings.tooltipTitle}</button>
                                <div class="${this.settings.cssNamespace}-tooltip-body  js-cc-tooltip-body">
                                    <button type="button" class="${this.settings.cssNamespace}-tooltip-close  js-cc-tooltip-close" aria-label="${this.settings.labelClose}">
                                        <svg class="${this.settings.cssNamespace}-icon" viewBox="0 0 17 17"><path d="M15.4 0L8.5 6.9 1.7.1.1 1.7l6.8 6.8L0 15.4 1.6 17l6.9-6.9 7 6.9 1.5-1.5-6.9-7L17 1.6z"/></svg>
                                    </button>
                                    <p>${this.settings.tooltipContent}</p>
                                </div>
                            </div>
                        </div>
                        <div class="${this.settings.cssNamespace}-groups" id="cc-groups">
                            <div class="${this.settings.cssNamespace}-group">
                                <h3>${this.settings.necessaryTitle}</h3>
                                <p>${this.settings.necessaryDescription}</p>
                            </div>
                        </div>
                        <p class="${this.settings.cssNamespace}-footer">
                            <button type="button" class="${this.settings.cssNamespace}-btn  ${this.settings.cssNamespace}-btn-tick  ${this.settings.cssNamespace}-btn-accept  js-cc-tick" data-cc-accept-recommended="true">
                                ${this.settings.labelAcceptRecommendedSettings}
                                <svg class="${this.settings.cssNamespace}-icon" viewBox="0 0 24 17.9"><path d="M24 2.3L21.7 0 8.4 13.3 2.3 7.2 0 9.5l6.8 6.8 1.6 1.6 1.6-1.6z"/></svg>
                            </button>
                            <button type="button" class="${this.settings.cssNamespace}-btn  ${this.settings.cssNamespace}-btn-tick  ${this.settings.cssNamespace}-btn-accept  js-cc-tick" data-cc-close="true">
                                ${this.settings.labelSaveAndExit}
                                <svg class="${this.settings.cssNamespace}-icon" viewBox="0 0 24 17.9"><path d="M24 2.3L21.7 0 8.4 13.3 2.3 7.2 0 9.5l6.8 6.8 1.6 1.6 1.6-1.6z"/></svg>
                            </button>
                        </p>
                    </div>`

            if (this.settings.creditContent) {
                html += `<p class="${this.settings.cssNamespace}-credit">${this.settings.creditContent}</p>`
            }

            html +=
                `</div>
            </div>`

            document.querySelector('body').insertAdjacentHTML('beforeend', html)

            this.settings.optionalGroups.forEach((group, i) => {
                let html =
                    `<div class="${this.settings.cssNamespace}-group">
                    <div class="${this.settings.cssNamespace}-split">
                        <h3 class="${this.settings.cssNamespace}-split-title">${group.label}</h3>
                        <label class="${this.settings.cssNamespace}-switch">
                            <input type="checkbox" class="${this.settings.cssNamespace}-switch-control  js-cc-group-check" data-group-index="${i}">
                            <span class="${this.settings.cssNamespace}-switch-body">
                                <span>${this.settings.labelOn}</span>
                                <span>${this.settings.labelOff}</span>
                            </span>
                        </label>
                    </div>
                    <p>${group.description}</p>
                </div>`

                document.querySelector('#cc-groups').insertAdjacentHTML('beforeend', html)
            })

            if (this.settings.layout === 'drawer' || this.settings.layout === 'banner') {
                let html =
                    `<div class="${this.settings.cssNamespace}-banner-wrapper" id="cc-banner">
                    <div class="${this.settings.cssNamespace}-banner">
                        <div class="${this.settings.cssNamespace}-banner-container">
                            <p class="${this.settings.cssNamespace}-banner-text">${this.settings.bannerContent}</p>

                            <p class="${this.settings.cssNamespace}-banner-footer">
                                <button type="button" class="${this.settings.cssNamespace}-btn  ${this.settings.cssNamespace}-btn-tick  ${this.settings.cssNamespace}-btn-accept  js-cc-tick" data-cc-accept-recommended="true">
                                    ${this.settings.labelAcceptRecommendedSettings}
                                    <svg class="${this.settings.cssNamespace}-icon" viewBox="0 0 24 17.9"><path d="M24 2.3L21.7 0 8.4 13.3 2.3 7.2 0 9.5l6.8 6.8 1.6 1.6 1.6-1.6z"></path></svg>
                                </button>
                                <button type="button" class="${this.settings.cssNamespace}-btn  ${this.settings.cssNamespace}-btn-accept  js-cc-learn-more">${this.settings.learnMoreBtn}</button>
                                <a href="${this.settings.cookiePolicyLink}" class="${this.settings.cssNamespace}-btn  ${this.settings.cssNamespace}-btn-bare">${this.settings.cookiePolicyBtn}</a>
                            </p>
                        </div>
                    </div>
                </div>`

                this.settings.prependDrawer.insertAdjacentHTML('afterbegin', html)

                if (this.settings.layout === 'banner' && document.querySelector('#cc-banner')) {
                    document.querySelector('#cc-banner').classList.add('cc-banner-fixed')
                }
            }
        },

        addEventListeners () {
            document.querySelector('#cc-toggle').addEventListener('click', () => {
                this.open()
            })

            document.querySelectorAll('.js-cc-tick').forEach((element) => {
                element.addEventListener('click', () => {
                    if (element.dataset.ccAcceptRecommended === 'true') {
                        this.acceptRecommended()
                    } else if (element.dataset.ccClose === 'true') {
                        this.close()
                    }

                    /*
                    element.classList.add('is-showing-tick');

                    setTimeout(() => {
                        if (element.dataset.ccAcceptRecommended === 'true') {
                            this.acceptRecommended();
                        } else if (element.dataset.ccClose === 'true') {
                            this.close();
                        }

                        element.classList.remove('is-showing-tick');
                    }, this.settings.animSpeed * 2);
                    */
                })
            })

            document.querySelectorAll('.js-cc-group-check').forEach((element) => {
                element.addEventListener('change', () => {
                    const groupId = parseInt(element.dataset.groupIndex)

                    if (element.checked === true) {
                        this.groupAllow(groupId)
                    } else {
                        this.groupDisallow(groupId)
                    }
                })
            })

            document.querySelectorAll('.js-cc-learn-more').forEach((element) => {
                element.addEventListener('click', () => {
                    this.modalOpen('#cc-step-2')
                })
            })

            document.querySelectorAll('.js-cc-close').forEach((element) => {
                element.addEventListener('click', () => {
                    this.close()
                })
            })

            // Close on overlay click, once the modal has already been
            // dismissed once
            document.querySelector('#cc').addEventListener('click', (e) => {
                if (e.target.matches('#cc') && Cookies.get('ccDismissed') === 'true') {
                    this.close()
                }
            })

            // Close on escape key
            document.addEventListener('keyup', (e) => {
                if (e.keyCode == 27 && document.querySelector('#cc.is-active').length && Cookies.get('ccDismissed') === 'true') {
                    this.close()
                }
            })

            document.querySelectorAll('.js-cc-back').forEach((element) => {
                element.addEventListener('click', () => {
                    this.modalOpen('#cc-step-1')
                })
            })

            document.querySelector('#cc-step-2').addEventListener('modal.open', () => {
                document.querySelectorAll('.js-cc-back').forEach((element) => {
                    element.classList.add('is-active')
                })
            })

            document.querySelector('#cc-step-2').addEventListener('modal.close', () => {
                document.querySelectorAll('.js-cc-back').forEach((element) => {
                    element.classList.remove('is-active')
                })
            })

            document.querySelectorAll('.js-cc-tooltip-toggle').forEach((element) => {
                element.addEventListener('click', () => {
                    element.closest('.js-cc-tooltip').classList.toggle('is-active')
                })
            })

            document.querySelectorAll('.js-cc-tooltip-close').forEach((element) => {
                element.addEventListener('click', () => {
                    element.closest('.js-cc-tooltip').classList.remove('is-active')
                })
            })

            document.addEventListener('click', () => {
                if (document.querySelectorAll('.js-cc-tooltip.is-active').length) {
                    document.querySelector('.js-cc-tooltip.is-active').classList.remove('is-active')
                }
            })

            document.querySelectorAll('.js-cc-tooltip').forEach((element) => {
                element.addEventListener('click', (e) => {
                    e.stopPropagation()
                })
            })

            window.addEventListener('resize', () => {
                this.tooltipPosition()
                this.groupsAutosize()
            })

            window.addEventListener('load', () => {
                this.tooltipPosition()
                this.groupsAutosize()
            })
        },

        checkAllowed () {
            const checks = document.querySelectorAll('.js-cc-group-check')

            this.settings.optionalGroups.forEach((group, index) => {
                if (Cookies.get(group.name) === 'allowed' || group.defaultState === 'on' && Cookies.get(group.name) !== 'disallowed') {
                    checks[index].checked = true
                    checks[index].dispatchEvent(new CustomEvent('change'))
                }

                if (Cookies.get(group.name) !== 'allowed') {
                    group.cookies.forEach((cookie) => {
                        Cookies.remove(cookie)
                    })
                }
            })
        },

        open () {
            if (Cookies.get('ccDismissed') === 'true') {
                document.querySelectorAll('.js-cc-close').forEach((element) => {
                    element.classList.add('is-active')
                })
            }

            this.modalOpen('#cc-step-1')
        },

        close () {
            this.modalClose('.js-cc-modal-content.is-active')

            if (Cookies.get('ccDismissed') !== 'true') {
                Cookies.set('ccDismissed', 'true', { expires: this.settings.expiry })
            }

            if (this.settings.layout === 'drawer') {
                const banner = document.querySelector('#cc-banner')
                const bannerHeight = banner.scrollHeight

                banner.style.transition = ''
                requestAnimationFrame(() => {
                    banner.style.height = `${bannerHeight}px`
                    banner.style.transition = `height ${this.settings.animSpeed}ms ease-in-out`

                    requestAnimationFrame(() => {
                        banner.style.height = '0px'
                    })
                })
            } else if (this.settings.layout === 'banner') {
                const banner = document.querySelector('#cc-banner')

                banner.classList.remove('is-active')
            }
        },

        modalOpen (selector) {
            const modal = document.querySelector('#cc')
            const content = document.querySelector(selector)

            content.dispatchEvent(new CustomEvent('modal.open'))

            if (!modal.classList.contains('is-active')) {
                // Show the modal and contents
                modal.classList.add('is-active')
                content.classList.add('is-active')
                window.dispatchEvent(new CustomEvent('customResize'))

                content.setAttribute('tabindex', '-1')
                content.focus()
                modal.scrollTop = 0

                document.querySelector('html').classList.add(`has-${this.settings.cssNamespace}-lock`)
            } else {
                content.style.visibility = 'hidden'
                content.style.display = 'block'

                window.dispatchEvent(new CustomEvent('customResize'))

                const oldContent = document.querySelector('.js-cc-modal-content.is-active')
                const oldWidth = oldContent.offsetWidth
                const newWidth = content.offsetWidth
                const newHeight = content.offsetHeight

                content.removeAttribute('style')
                oldContent.dispatchEvent(new CustomEvent('modal.close'))

                // If the new modal is taller than the screen,
                // we'll need to scroll back to top
                if (newHeight > window.innerHeight) {
                    modal.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    })
                }

                oldContent.style.transition = `opacity ${this.settings.animSpeed}ms`
                oldContent.style.opacity = 0

                const opacityComplete = () => {
                    oldContent.removeEventListener('transitionend', opacityComplete)

                    // Setup ready for transitioning width and height
                    oldContent.style.maxWidth = 'none'
                    oldContent.style.width = `${oldWidth}px`
                    oldContent.style.height = `${oldContent.scrollHeight}px`

                    requestAnimationFrame(() => {
                        oldContent.style.transition = `width ${this.settings.animSpeed}ms ease-in-out, height ${this.settings.animSpeed}ms ease-in-out`

                        requestAnimationFrame(() => {
                            oldContent.style.width = `${newWidth}px`
                            oldContent.style.height = `${newHeight}px`

                            const dimensionsComplete = () => {
                                oldContent.removeEventListener('transitionend', dimensionsComplete)
                                oldContent.style.display = 'none'

                                content.style.display = 'block'
                                content.style.opacity = 0

                                requestAnimationFrame(() => {
                                    content.style.transition = `opacity ${this.settings.animSpeed}ms`

                                    requestAnimationFrame(() => {
                                        content.style.opacity = 1

                                        const opacityComplete = () => {
                                            content.removeEventListener('transitionend', opacityComplete)
                                            content.setAttribute('tabindex', '-1')
                                            content.focus()
                                            modal.scrollTop = 0
                                            content.classList.add('is-active')
                                            content.removeAttribute('style')
                                            oldContent.classList.remove('is-active')
                                            oldContent.removeAttribute('style')
                                            oldContent.classList.remove('is-active')
                                            oldContent.removeAttribute('tabindex')
                                        }

                                        content.addEventListener('transitionend', opacityComplete)
                                    })
                                })
                            }

                            oldContent.addEventListener('transitionend', dimensionsComplete)
                        })
                    })
                }

                oldContent.addEventListener('transitionend', opacityComplete)
            }
        },

        modalClose (selector) {
            const content = document.querySelector(selector)

            if (!content) {
                return false
            }

            content.dispatchEvent(new CustomEvent('modal.close'))
            content.removeAttribute('tabindex')

            document.querySelector('#cc').classList.remove('is-active')
            setTimeout(() => {
                content.classList.remove('is-active')
            }, this.settings.animSpeed)

            document.querySelector('html').classList.remove(`has-${this.settings.cssNamespace}-lock`)
        },

        groupAllow (index) {
            const group = this.settings.optionalGroups[index]

            Cookies.set(group.name, 'allowed', { expires: this.settings.expiry })
            group.onAllow.call()
        },

        groupDisallow (index) {
            const group = this.settings.optionalGroups[index]

            if (group.defaultState === 'on') {
                Cookies.set(group.name, 'disallowed')
            } else {
                Cookies.remove(group.name)
            }
            group.cookies.forEach((cookie) => {
                Cookies.remove(cookie)
            })
            group.onDisallow.call()
        },

        acceptRecommended () {
            const checks = document.querySelectorAll('.js-cc-group-check')

            this.settings.optionalGroups.forEach((group, i) => {
                if (group.recommendedState === 'on' && checks[i].checked !== true) {
                    checks[i].checked = true
                    checks[i].dispatchEvent(new CustomEvent('change'))
                }

                if (group.recommendedState === 'off' && checks[i].checked !== false) {
                    checks[i].checked = false
                    checks[i].dispatchEvent(new CustomEvent('change'))
                }
            })

            this.close()

        },

        tooltipPosition () {
            if (!document.querySelector('#cc-script-styles')) {
                document.querySelector('head').insertAdjacentHTML('beforeend', '<style id="cc-script-styles" />')
            } else {
                document.querySelector('#cc-script-styles').textContent = ''
            }

            document.querySelectorAll('.js-cc-tooltip-body').forEach((element, i) => {
                element.setAttribute('id', `cc-tooltip-${i}`)
                element.removeAttribute('style')

                const rect = element.getBoundingClientRect()
                const left = rect.left + document.body.scrollLeft
                const right = (window.innerWidth - (left + element.offsetWidth))

                if (left < 12) {
                    element.style.marginLeft = `${12 - left}px`
                    document.querySelector('#cc-script-styles').insertAdjacentText('beforeend', `#cc-tooltip-${i}::before { margin-left:${(0 - (12 - left))}px; }`)
                }

                if (right < 12) {
                    element.style.marginLeft = `${0 - (12 - right)}px`
                    document.querySelector('#cc-script-styles').insertAdjacentText('beforeend', `#cc-tooltip-${i}::before { margin-left:${(12 - right)}px; }`)
                }
            })
        },

        groupsAutosize () {
            const groups = document.querySelector('#cc-groups')
            groups.style.display = 'none'

            let height = window.innerHeight - (document.querySelector('#cc').offsetHeight - innerHeight(document.querySelector('#cc')))

            height -= document.querySelector('#cc-step-2').offsetHeight
            groups.style.display = 'block'
            height -= outerHeight(groups) - groups.offsetHeight

            groups.style.height = `${height}px`
        },
    }

    window.CookieController = CookieController

})(window, document)
